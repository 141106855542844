// Import React from "react" module
import React, {useEffect} from "react";
import './Wheel.css';

// Define a function component called Wheel
function Wheel() {
  // Declare some variables for the wheel properties
  var tamanyoRuleta = 360;
  var numeroCasillas = 5;
  var anguloCasillas = 360 / numeroCasillas;
  var grados = (180 - anguloCasillas) / 2;
  var alturaCasilla = Math.tan((grados * Math.PI) / 180) * (tamanyoRuleta / 2);

  const ruletaStyles = {
    width: tamanyoRuleta + 'px',
    height: tamanyoRuleta + 'px',
  };

  useEffect(() => {
    const style = document.createElement("style");
    style.id = "animacionRuleta";
    document.head.appendChild(style);
  })

  // Define a function to generate random colors
  function getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  // Define a function to handle the click event on the wheel
  function handleClick() {
    var num;
    var numID = "number-";
    num = 1 + Math.round(Math.random() * (numeroCasillas - 1));
    numID += num;

    // Remove the previous animation style
    document.getElementById("animacionRuleta").remove();
    // Create a new style element with the animation keyframes
    var style = document.createElement("style");
    style.id = "animacionRuleta";
    style.innerHTML = `
      #number-${num} { animation-name: number-${num}; } 
      @keyframes number-${num} {
        from { transform: rotate(0); } 
        to { transform: rotate(${
          360 * (numeroCasillas - 1) - anguloCasillas * num
        }deg); }
      }
    `;
    // Append the style element to the head
    document.head.appendChild(style);
    // Remove the previous id from the wheel element
    let elem = document.getElementsByClassName("ruleta");
    elem[0].id=numID;


  }

  // Return JSX that renders the wheel element
  return (
    <div id="ruleta" className="ruleta" style={ruletaStyles} onClick={handleClick}>
      {/* Use a loop to create the option elements */}
      {Array.from({ length: numeroCasillas }, (v, i) => i + 1).map((n) => (
        <div
          key={n}
          className={`opcion opcion-${n}`}
          style={{
            transform: `rotate(${anguloCasillas * n}deg)`,
            borderBottomColor: getRandomColor(),
            borderBottomWidth: alturaCasilla + "px",
            borderRightWidth: tamanyoRuleta / 2 + "px",
            borderLeftWidth: tamanyoRuleta / 2 + "px",
          }}
        >
          {/* Use a pseudo-element to display the number */}
        </div>
      ))}
    </div>
  );
}

// Export the Wheel component
export default Wheel;
