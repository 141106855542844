
import './ExamplePlugin.css';
import Participants from './participants/Participants';
import SessionDetails from './session/SessionDetails';
import Wheel from './wheel/Wheel';

export default function ExamplePlugin() {


    return (
        <div className="contents">
            <SessionDetails/>
            <Participants/>
            <Wheel></Wheel>
        </div>
    )

}